import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'
import { format } from 'date-fns'

export const useSearchContractStore = defineStore('searchContractStore', {
  state: () => ({
    contractDate: format(new Date(), 'yyyy-MM-dd') as string | null,
    status: null as number | null,
    validFrom: null as string | null,
    validUntil: null as string | null,
    producerNumber: null as number | null,
    animalTypeId: null as number | null,
    encashmentOrganisationAgisId: null as number | null,
    items: [
      { key: 'contractDate', operation: ':', defaultValue: format(new Date(), 'yyyy-MM-dd') },
      { key: 'status', operation: ':' },
      { key: 'validFrom', operation: ':' },
      { key: 'validUntil', operation: ':' },
      { key: 'producerNumber', operation: ':' },
      { key: 'animalTypeId', operation: ':' },
      { key: 'encashmentOrganisationAgisId', operation: ':' }
    ] as TermItemDefinition[]
  })
})
