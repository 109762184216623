<template>
  <div>
    <h2 v-html="$t('search_contract_title')"></h2>
    <!--    Stichtag-->
    <dbmDatePicker :dateLabel="$t('search_contract_date')" v-model="searchCardStore.contractDate" clearable></dbmDatePicker>
    <!--    Status-->
    <v-select
      id="search_contract_status"
      v-model="searchCardStore.status"
      :label="$t('search_contract_status')"
      :items="activeOptions"
      :item-title="this.$getLangKey()"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
    <!--    Tierart-->
    <v-select
      id="search_contract_animalType_id"
      v-model="searchCardStore.animalTypeId"
      :label="$t('search_contract_animal_type')"
      :items="animalTypes"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!--    Inkassostelle-->
    <v-select
      id="search_contract_encashment_organisation_id"
      v-model="searchCardStore.encashmentOrganisationAgisId"
      :label="$t('search_contract_encashment_organisation')"
      :items="productionEncashmentOrganisations"
      item-title="name"
      item-value="agisId"
      clearable
      @keyup.enter="search"
      :loading="this.loadingProductionEncashmentOrganisations"
    />
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!--    Produzentennummer-->
      <v-text-field
        id="search_contract_name1"
        v-model="searchCardStore.producerNumber"
        :label="$t('search_contract_producer_number')"
        type="text"
        @keyup.enter="search"
      />
      <!--    Vertrag von-->
      <dbmDatePicker :dateLabel="$t('search_contract_valid_from')" v-model="searchCardStore.validFrom" clearable></dbmDatePicker>
      <!--    Vertrag bis-->
      <dbmDatePicker :dateLabel="$t('search_contract_valid_until')" v-model="searchCardStore.validUntil" clearable></dbmDatePicker>
      <!--    Vertrag-Detail von-->
      <!--    Vertrag-Detail bis-->
      <!--    AGIS-ID Sammelstelle-->
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '../dbmDatePicker.vue'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useSearchContractStore } from '@/store/SearchContractStore'
import { useOrganisationsStore } from '@/store/enums/OrganisationsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchContract',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      activeOptions: [
        {
          nameDe: this.$t('search_contract_status_active'),
          nameFr: '',
          nameIt: '',
          value: 1
        },
        {
          nameDe: this.$t('search_contract_status_inactive'),
          nameFr: '',
          nameIt: '',
          value: 0
        }
      ]
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  computed: {
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    productionEncashmentOrganisations() {
      return useOrganisationsStore().productionEncashmentOrganisations
    },
    loadingProductionEncashmentOrganisations() {
      return useOrganisationsStore().loadingproductionEncashmentOrganisations
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchContractStore(), true)
    useAnimalTypesStore().fetchAnimalTypes()
    useOrganisationsStore().fetchProductionEncashmentOrganisations()
  }
})
</script>
